.team-container {
    max-width: 1200px; /* Restricts overall width */
    margin: 0 auto; /* Centers content */
    padding: 20px; /* Adds spacing */
    text-align: center;
    box-sizing: border-box;
}

.team-container h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    text-align: left; /* Align the heading to the left */
    font-family: 'Noto Sans', sans-serif;
}

.team-container p {
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: left; /* Align the paragraph to the left */
    font-family: 'Noto Sans', sans-serif;
}

.team-grid {
    display: flex; /* Default layout with flexbox */
    justify-content: space-between; /* Space out the columns */
    gap: 20px;
    max-width: 1200px; /* Keeps the grid width consistent */
    margin: 0 auto; /* Centers the grid */
}

.team-column {
    flex: 1; /* Equal space for each column */
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.team-card {
    width: 350px; /* Fixed width for all cards */
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px; /* Standard padding for all cards */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease, height 0.3s ease;
    overflow: hidden; /* Prevents description overflow */
    display: flex; /* Use flexbox for layout */
    flex-direction: column;
    justify-content: space-between; /* Ensures all cards have the same structure */
    min-height: 85px; /* Ensures uniform height for cards */
    box-sizing: border-box;
    word-wrap: break-word; /* Ensures long text wraps within the card */
}

.team-card.expanded {
    height: auto; /* Allows vertical growth */
    overflow: visible; /* Ensure expanded content is visible */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional for highlighting */
}

.team-card:hover {
    background-color: #f0f0f0; /* Slightly darkens the background */
    transform: scale(1.02);
}

.team-card.no-description {
    background-color: #f0f0f0; /* Gray out no-description contacts */
    cursor: default; /* Indicates that this card is not interactive */
    justify-content: center; /* Centers content vertically */
    align-items: center; /* Centers text horizontally */
    text-align: center; /* Ensures text alignment remains centered */
    padding: 15px; /* Maintain consistent padding */
}

.team-card.no-phone {
    justify-content: center; /* Centers content vertically */
    align-items: center; /* Centers text horizontally */
    text-align: center; /* Ensures text alignment remains centered */
    padding: 15px; /* Maintain consistent padding */
}

.team-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.team-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Keeps text aligned to the left */
    justify-content: flex-start;
    gap: 5px;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
}

.team-left h2 {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    font-weight: normal;
    font-family: 'Noto Sans', sans-serif;
    word-wrap: break-word; /* Ensures text wraps if too long */
    text-align: left; /* Keeps text left-aligned */
}

.team-left p {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    color: black;
    font-family: 'Noto Sans', sans-serif;
    text-align: left;
}

.phone-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 24 24'%3E%3Cpath d='M6.62 10.79a15.91 15.91 0 006.59 6.59l2.2-2.2a1 1 0 01.91-.27 11.72 11.72 0 003.7.6 1 1 0 011 1v3.79a1 1 0 01-1 1A16 16 0 013 4a1 1 0 011-1h3.79a1 1 0 011 1 11.72 11.72 0 00.6 3.7 1 1 0 01-.27.91z'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
}

.team-title {
    background: #f9f9f9;
    padding: 6px 12px;
    border-radius: 12px;
    font-family: 'Noto Sans', sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    border: 1px solid #ddd;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: black;
}

.team-description {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #555;
    display: block;
    font-family: 'Noto Sans', sans-serif;
    text-align: left;
}


/* Media Query for two columns at 1090px or below */
@media (max-width: 1090px) {
    .team-grid {
        display: grid; /* Use grid layout */
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        gap: 20px; /* Space between cards */
    }

    .team-row {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Two columns in a row */
        gap: 20px;
    }

    .team-row:last-child {
        grid-template-columns: 1fr; /* Single column for the last row */
        justify-content: flex-start; /* Align the single card to the left */
    }

    .team-card {
        flex: unset; /* Reset flex property to work with grid */
        width: 100%; /* Take full width of the grid cell */
    }
}

/* Media Query for one column at 720px or below */
@media (max-width: 720px) {
    .team-grid {
        display: flex; /* Flex layout for single-column */
        flex-direction: column; /* Stack cards vertically */
        gap: 20px; /* Space between rows */
    }

    .team-card {
        width: 100%; /* Take full width in single-column layout */
    }
}
