/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

/* Container Styling */
.container {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin: 0;
  padding: 0;
  max-width: 100%;
  height: 100%;
}

/* Background Image Fix */
img {
  max-width: 100%;
  height: auto;
  display: block; /* Prevent inline image spacing issues */
}
