.carousel-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Reduced gap for tighter alignment */
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
}

.carousel-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: 900 !important;
  font-size: 1.5rem; /* Matches responsive title size */
  margin: 0 0 8px; /* Reduced bottom margin */
  text-align: center;
}

.carousel-subtitle {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400; /* Normal weight */
  font-size: 1rem; /* Matches responsive caption size */
  color: black;
  margin: 0 0 16px; /* Adjust bottom spacing */
  text-align: center;
}

.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 550px;
  overflow: hidden;
}

.carousel-image {
  position: absolute;
  height: 450px;
  width: 400px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  z-index: 0;
  opacity: 0;
}

.carousel-image.center {
  transform: translateX(0) scale(1.2);
  z-index: 2;
  opacity: 1;
}

.carousel-image.left {
  transform: translateX(-125%) scale(0.9);
  z-index: 1;
  opacity: 1;
}

.carousel-image.right {
  transform: translateX(125%) scale(0.9);
  z-index: 1;
  opacity: 1;
}

.carousel-image.near-left {
  transform: translateX(-200%) scale(0.8);
  z-index: 0;
  opacity: 0.5;
}

.carousel-image.near-right {
  transform: translateX(200%) scale(0.8);
  z-index: 0;
  opacity: 0.5;
}

.carousel-image.far-left {
  transform: translateX(-300%) scale(0.8);
  z-index: 0;
  opacity: 0;
}

.carousel-image.far-right {
  transform: translateX(300%) scale(0.8);
  z-index: 0;
  opacity: 0;
}

.carousel .carousel-button {
  position: absolute;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease, transform 0.2s ease;
  padding: 0;
  margin: 0;
}

.carousel .carousel-button.left {
  left: 5%;
  top: 50%;
  transform: translateY(-50%);
}

.carousel .carousel-button.right {
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}

.carousel .carousel-icon {
  font-size: 24px;
  color: black;
}

.MuiIconButton-root.carousel-button {
  padding: 0 !important;
  margin: 0 !important;
  background-color: white !important;
}
